import React from "react";
import AppHeader from "../components/AppHeader";
import {
  getApplications,
  deleteApplicationItem,
  //validateApplicationItem,
  putApplication,
  postApplication,
  getAccessToken,
} from "../api/services";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormDialog from "../components/FormDialog";
import Box from "@mui/material/Box";
import AdminMenu from "../components/AdminMenu";
import { createNewContact, createNewTrademark, uploadAttachment } from "../api/trademark-services";
import { getNewContactPayload } from "../utils/getNewContactPayload";
import { getNewTrademarkPayload } from "../utils/getNewTrademarkPayload";
import { withAuth } from "../HOC";

function Admin() {
  const [applications, setApplications] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [targetApplication, setTargetApplication] = useState({});
  const [isContactSubmitting, setIsContactSubmitting] = useState(false);
  const [isImageSubmitting, setIsImageSubmitting] = useState(false);
  const [isTrademarkSubmitting, setIsTrademarkSubmitting] = useState(false);

  const fetchApplications = async () => {
    setIsFetching(true);
    const response = await getApplications();
    console.log(response);
    setApplications(response);
    setIsFetching(false);
  };

  const handleCreate = () => {
    setTargetApplication({});
    setIsOpen(true);
  };

  const handleEdit = (application) => {
    setIsOpen(true);
    setTargetApplication(application);
  };

  const handleDelete = async ({ id }) => {
    // Replace with dialog delete confirmation
    submitDelete(id);
  };

  // Submit
  const submitNewContact = async (application) => {
    const payload = getNewContactPayload(application);
    setIsContactSubmitting(true)
    const data = await createNewContact(payload);
    setIsContactSubmitting(false)

    if (data?.identifier) {
      window.alert("Created contact: " + data.identifier)
      try {
        handleSubmit({ ...application, contactIdentifier: data.identifier });
      } catch (ex) {
        window.alert("Failed to update database. Please note following ID and alert admin: " + data.identifier);
      }
      return data.identifier;
    } else {
      window.alert("Error creating new contact:" + JSON.stringify(data, null, 2))
    }
  };

  const submitAttachment = async (application) =>{
    setIsImageSubmitting(true)
    const data = await uploadAttachment(application.trademarkLogo)
    setIsImageSubmitting(false)

    if (data?.attachmentId) {
      window.alert("Uploaded Attachment: " + data.attachmentId)
      try{
        handleSubmit({ ...application, imageIdentifier: data.attachmentId });
      } catch (ex) {
        window.alert("Failed to update database. Please note following ID and alert admin: " + data.identifier);
      }
    } else {
      window.alert("Error uploading image");
    }
  }

  const submitNewTrademark = async (application, validationOnly = false) => {
    const payload = getNewTrademarkPayload(application);
    setIsTrademarkSubmitting(true)
    const data = await createNewTrademark(payload, validationOnly);
    setIsTrademarkSubmitting(false)

    if(data?.identifier){
      window.alert(`Created Trademark: ${data.tradeMark.auApplicationNumber} (${data.identifier})`)
      try {
        handleSubmit({
          ...application,
          applicationIdentifer: data.identifier,
          applicationNumber: data.tradeMark.auApplicationNumber,
          applicationFee: data.fee.feePayableTotalAmount,
        });
      } catch (ex) {
        window.alert(
          "Failed to update database. Please note following ID and alert admin: " +
            data.auApplicationNumber +
            " | " +
            data.identifier
        );
      }
    } else {
      window.alert("Error submitting trademark")
    }
  };

  const submitDelete = async (id) => {
    // window.alert("Disabled")
    const response = await deleteApplicationItem(id);
    console.log(response);
    fetchApplications();
  };

  const handleSubmit = async (payload) => {
    const response = payload.id
      ? await putApplication(payload, payload.id)
      : await postApplication(payload);
    console.log(response);
    fetchApplications();
    setIsOpen(false);
  };

  // Dialog
  const handleCloseDialog = (_, reason) => {
    if (reason === "backdropClick") return;
    setIsOpen(false);
  };

  const fetchIpToken = async () => {
    const token = await getAccessToken();
    localStorage.setItem("ipToken", token);
  };

  // NOTE: Watch JSON structure when refactoring as it is stored as string
  const SelectionComponent = ({selections}) => {
    return selections?.map(({ classNumber }, i) => classNumber).join(", ");
  };

  const getForeignTextDisplay = (foreignLanguageDefinitions) => {
    try {
      return foreignLanguageDefinitions
        ? JSON.parse(foreignLanguageDefinitions).map(
            ({
              language,
              foreignLanguageText,
              translation,
              transliteration,
            }) => {
              return (
                <div>
                  <div>{language}</div>
                  <div>{foreignLanguageText}</div>
                  <div>{translation}</div>
                  <div>{transliteration || "-"}</div>
                </div>
              );
            }
          )
        : [];
    } catch (error) {
      return <span style={{ color: "red" }}>Data ERROR</span>;
    }
  };

  return (
    <div>
      <AppHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 8px",
        }}
      >
        <Button
          onClick={() => {
            fetchApplications();
            fetchIpToken();
          }}
          variant="outlined"
          disabled={isFetching}
        >
          {isFetching ? "Loading..." : "Get Applications"}
        </Button>
        {/* Get Applications button */}
        <FormDialog
          open={isOpen}
          targetApplication={targetApplication}
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleCloseDialog={handleCloseDialog}
        />
      </Box>
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table
          id="adminTable"
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">Action</TableCell>
              <TableCell as="th">Id</TableCell>
              <TableCell as="th">ContactIdentifier</TableCell>
              <TableCell as="th">ImageIdentifier</TableCell>
              <TableCell as="th">ApplicationNumber</TableCell>
              <TableCell as="th">Fee</TableCell>
              <TableCell as="th">TM Type</TableCell>
              <TableCell as="th">TM Text</TableCell>
              <TableCell as="th">Owner Type</TableCell>
              <TableCell as="th">Company Name</TableCell>
              <TableCell as="th">First Name</TableCell>
              <TableCell as="th">Last Name</TableCell>
              <TableCell as="th">ABN/ACN</TableCell>
              <TableCell as="th">Email</TableCell>
              <TableCell as="th">Phone</TableCell>
              <TableCell as="th">Address</TableCell>
              <TableCell as="th">Suburb</TableCell>
              <TableCell as="th">Postcode</TableCell>
              <TableCell as="th">State</TableCell>
              <TableCell as="th">Country</TableCell>
              <TableCell as="th">Logo</TableCell>
              <TableCell as="th">Selections</TableCell>
              <TableCell as="th">Foreign Language</TableCell>
              <TableCell as="th">TOS</TableCell>
              <TableCell as="th">Created</TableCell>
              <TableCell as="th">Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications?.map((note) => {

              const ContactIdentifier = () => {
                return (
                  note.contactIdentifier || (
                    <Button disabled={isContactSubmitting} onClick={() => submitNewContact(note)}>Add</Button>
                  )
                );
              };

              const ImageIdentifier = () => {
                if(note.trademarkType === 1) return "N/A";
                if (!note.trademarkLogo) return "Error: No Logo";
                return (
                  note.imageIdentifier || (
                    <Button disabled={isImageSubmitting} onClick={() => submitAttachment(note)}>
                      Add
                    </Button>
                  )
                );
              }

              const ApplicationNumber = () => {
                if (!note.contactIdentifier) {
                  return null;
                }
                if (!note.imageIdentifier && note.trademarkType === 2) {
                  return null;
                }

                return (
                  note.applicationNumber || (
                    <div  style={{textAlign: "center"}}>
                      <Button
                        disabled={isTrademarkSubmitting}
                        onClick={() => submitNewTrademark(note, true)}
                      >
                        Validate
                      </Button>
                      <div>OR</div>
                      <Button
                        disabled={isTrademarkSubmitting}
                        onClick={() => submitNewTrademark(note)}
                      >
                        Submit
                      </Button>
                    </div>
                  )
                );
              };

              return (
                <TableRow key={note.id}>
                  <TableCell>
                    <AdminMenu
                      onEdit={() => handleEdit(note)}
                      onDelete={() => handleDelete(note)}
                    />
                  </TableCell>
                  <TableCell>{note.id}</TableCell>
                  <TableCell>
                    <ContactIdentifier />
                  </TableCell>
                  <TableCell>
                    <div className="text-center">
                      <ImageIdentifier />
                    </div>
                  </TableCell>
                  <TableCell>
                    <ApplicationNumber />
                  </TableCell>
                  <TableCell>{note.applicationFee}</TableCell>
                  <TableCell>
                    {note.trademarkType === 1 ? "Word" : "Logo"}
                  </TableCell>
                  <TableCell>{note.trademarkText}</TableCell>
                  <TableCell>
                    {note.ownerType === 1 ? "Company" : "Individual"}
                  </TableCell>
                  <TableCell>{note.ownerName}</TableCell>
                  <TableCell>{note.firstName}</TableCell>
                  <TableCell>{note.lastName}</TableCell>
                  <TableCell>{note.abnAcn}</TableCell>
                  <TableCell>{note.email}</TableCell>
                  <TableCell>{note.phone}</TableCell>
                  <TableCell>
                    {note.addressLine1}
                    {note.addressLine2 && ", " + note.addressLine2}
                    {note.addressLine3 && ", " + note.addressLine3}
                  </TableCell>
                  <TableCell>{note.suburb}</TableCell>
                  <TableCell>{note.postcode}</TableCell>
                  <TableCell>{note.state}</TableCell>
                  <TableCell>{note.country}</TableCell>
                  <TableCell>
                    {note.trademarkLogo && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={note.trademarkLogo}
                      >
                        Link
                      </a>
                    )}
                  </TableCell>
                  <TableCell>
                    <SelectionComponent
                      selections={
                        note.selections ? JSON.parse(note.selections) : []
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {getForeignTextDisplay(note.foreignLanguageDefinitions)}
                  </TableCell>
                  <TableCell>
                    {note.hasAgreedToTOS ? "True" : "False"}
                  </TableCell>
                  <TableCell>
                    {note.createdDate.slice(0, 19).replace("T", " ")}
                  </TableCell>
                  <TableCell>
                    {note.modifiedDate?.slice(0, 19).replace("T", " ")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withAuth(Admin);
