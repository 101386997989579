import * as React from "react";
import Container from "@mui/material/Container";
import { ApplicationStepper } from "./components/ApplicationStepper";
import ApplicationTradeMarkDetails from "./ApplicationTradeMarkDetails";
import ApplicationGoodsAndServices from "./ApplicationGoodsAndServices";
import ApplicationOwnership from "./ApplicationOwnership";
import ApplicationSummaryPayment from "./ApplicationSummaryPayment";
import AppHeader from "./components/AppHeader";
import "./Application.css";
import countryList from "./json/countriesProd.json";
import { submitPaymentIntent } from "./api/services";
import PaymentProcessingDialog from "./components/PaymentProcessingDialog";
import { calculateTotalCost, SERVICE_CHARGE_COST } from "./utils/utils";

const steps = [
  "Trademark Details",
  "Goods & Services",
  "Ownership",
  "Summary & Payment",
];

function getStepContent(
  step,
  setFormValues,
  formValues,
  setSelections,
  selections,
  totalCost,
  apiResult,
  setIsStripeSubmitting,
  hasTermsAgreementError,
  logoError,
  setLogoError,
  onSubmitError,
  setOnSubmitError
) {
  switch (step) {
    case 0:
      return (
        <ApplicationTradeMarkDetails
          setFormValues={setFormValues}
          formValues={formValues}
          logoError={logoError}
          setLogoError={setLogoError}
        />
      );
    case 1:
      return (
        <ApplicationGoodsAndServices
          setSelections={setSelections}
          selections={selections}
        />
      );
    case 2:
      return (
        <ApplicationOwnership
          setFormValues={setFormValues}
          formValues={formValues}
          onSubmitError={onSubmitError}
          setOnSubmitError={setOnSubmitError}
        />
      );
    case 3:
      return (
        <ApplicationSummaryPayment
          setFormValues={setFormValues}
          formValues={formValues}
          selections={selections}
          totalCost={totalCost}
          apiResult={apiResult}
          setIsStripeSubmitting={setIsStripeSubmitting}
          hasTermsAgreementError={hasTermsAgreementError}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

const initialFormValues = {
  trademarkType: "1", // Text / Logo
  trademarkText: "",
  ownerType: "1", // Company
  ownerName: "",
  firstName: "",
  lastName: "",
  country: countryList[13], // 13th is Australia
  abnAcn: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  suburb: "",
  postcode: "",
  state: "",
  hasAgreedToTOS: false,
  // Language
  foreignLanguageYesNo: "No",
  hasForeignCharacters: false,
  foreignLanguageDefinitions: [],
};


export default function Application() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [selections, setSelections] = React.useState([]);
  const [totalCost, setTotalCost] = React.useState(SERVICE_CHARGE_COST);
  const [apiResult, setApiResult] = React.useState({}); // stripe payment intent
  const [hasTermsAgreementError, setHasTermsAgreementError] =
    React.useState(false);
  const [isStripeSubmitting, setIsStripeSubmitting] = React.useState(false);
  const [logoError, setLogoError] = React.useState("");
  const [onSubmitError, setOnSubmitError] = React.useState({});

  const handleNext = async () => {
    setHasTermsAgreementError(false);
    setOnSubmitError({});
    // 1. Trademark Details
    if (activeStep === 0) {
      if (formValues.trademarkType === "1" && !formValues.trademarkText) {
        document.querySelector("#trademarkText").reportValidity();
        return;
      }
      if (formValues.trademarkType === "1" && formValues.trademarkText.length > 255) {
        return;
      }
      if (formValues.trademarkType === "2" && !formValues.trademarkLogo) {
        setLogoError("Please upload your logo");
        return;
      }
      if (formValues.trademarkType === "2" && formValues.foreignLanguageYesNo === "Yes") {
        const form = document.querySelector("#foreignLanguageForm");
        const isValid = form.reportValidity();
        if (!isValid){
          return false;
        }
      }
      // Check image dimensions
      const img = document.querySelector(".drop-zone .preview-item img");
      if (
        formValues.trademarkType === "2" &&
        (img.naturalWidth < 158 || img.naturalHeight < 158)
      ) {
        setLogoError("Please ensure your logo is at least 158 x 158 pixels");
        return;
      }
      setLogoError("");
    }
    // 2. Goods & Services
    if (activeStep === 1) {
      if (!selections?.length > 0) {
        // Note: Button is disabled so this should not trigger now
        window.alert("Select Goods & Services");
        return;
      }
    }
    // 3. Ownership
    if (activeStep === 2) {
      // Check Validity
      const form = document.querySelector("#ownerShipForm");
      const isValid = form.reportValidity();
      if (!isValid){
        return false;
      }

      // Check pattern
      const errors = {}
      if (
        formValues.ownerType === "1" &&
        formValues.country.countryCode === "AU" &&
        !/^[0-9]{9}$/.test(form.elements.abnAcn.value)
      ) {
        errors.abnAcn = "ACN is in an incorrect format. It must be a nine-digit number."
        // setOnSubmitError({
        //   ...onSubmitError,
        //   abnAcn:
        //     "ACN is in an incorrect format. It must be a nine-digit number.",
        // });
      }
      const AU_POSTCODE_REGEX = /^[0-9]{4}$/;
      if (
        formValues.country.countryCode === "AU" &&
        !AU_POSTCODE_REGEX.test(form.elements.postcode.value)
      ) {
        errors.postcode = "Postcode is in an incorrect format"
        // setOnSubmitError({
        //   ...onSubmitError,
        //   postcode: "Postcode is in an incorrect format",
        // });
      }

      setOnSubmitError(errors);

      console.log(onSubmitError)
      // HOTFIX
      if (
        (formValues.country.countryCode === "AU" &&
          !AU_POSTCODE_REGEX.test(form.elements.postcode.value)) ||
        (formValues.ownerType === "1" &&
          formValues.country.countryCode === "AU" &&
          !/^[0-9]{9}$/.test(form.elements.abnAcn.value))
      ) {
        return;
      }

      // HOTFIX: Prevent next step if invalid field error
      if (form.querySelector("[aria-invalid=true]")) {
        return;
      }

      // Clean payload
      const serializedSelections = selections.map(({ number, suggestions }) => {
        const obj = {
          classNumber: number,
          classDescriptions: suggestions.map(({ name }) => name),
        };
        return obj;
      });

      const payload = {
        ...formValues,
        country: formValues.country.countryCode,
        selections: serializedSelections,
      };
      console.log("PAYLOAD", payload);

      // Submit payment intent
      setIsStripeSubmitting(true);
      const result = await submitPaymentIntent(payload);
      setIsStripeSubmitting(false);
      console.log("Stripe Submitting result", result);

      if (!result) {
        window.alert("An Error has occured. Unable to contact Stripe");
        return;
      }
      setApiResult(result);
    }
    // 4. Summary screen - TODO: Refactor this hack
    if (activeStep === steps.length - 1) {
      console.log("TOS", formValues.hasAgreedToTOS);
      if (!formValues.hasAgreedToTOS) {
        setHasTermsAgreementError(true);
        return;
      }
      setHasTermsAgreementError(false);
      console.log(formValues);
      document.querySelector("#stripeSubmit").click();
      setIsStripeSubmitting(true);
      return;
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    const cost = calculateTotalCost(selections.length)
    setTotalCost(cost);
  }, [selections.length]);

  // console.log("FORMVALUES", formValues);
  // console.log("selections", selections);
  // console.log("ACTIVE", activeStep);

  return (
    <>
      <AppHeader />
      <PaymentProcessingDialog
        isOpen={isStripeSubmitting && activeStep === 3}
      />
      <Container component="main" maxWidth="lg">
        <ApplicationStepper steps={steps} activeStep={activeStep} />
        <div>
          {getStepContent(
            activeStep,
            setFormValues,
            formValues,
            setSelections,
            selections,
            totalCost,
            apiResult,
            setIsStripeSubmitting,
            hasTermsAgreementError,
            logoError,
            setLogoError,
            onSubmitError,
            setOnSubmitError
          )}
        </div>
        {/* FORM BAR */}
        <div className="form-fixed_bar">
          <div className="container">
            <div className="total">
              Total to Pay $
              <span id="variablePrice">{Math.max(500, totalCost)}</span>
            </div>
            <div className="buttons">
              {activeStep !== 0 && (
                <button
                  type="button"
                  className="button prev-form"
                  onClick={handleBack}
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                className="button next-form"
                onClick={handleNext}
                disabled={
                  isStripeSubmitting || (!selections.length && activeStep === 1)
                }
              >
                {activeStep === steps.length - 1
                  ? "Confirm and Pay"
                  : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
