import { Alert, Autocomplete, Box, Button, TextField } from "@mui/material";
import languages from "../json/languages.json";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export const ALPHA_NUMERIC_DASH_REGEX =
  // eslint-disable-next-line
  /^[ ~`!@#$%^&*()_+=\[\]\\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

export default function ForeignLanguage({
  index,
  foreignLanguageDefinitions,
  foreignLanguageDefinitionItem,
  handleChangeForeignLanguageDefinitions,
  removeAnotherTranslation,
}) {
  const [inputValue, setInputValue] = useState("");

  const {
    id,
    translation,
    transliteration,
    foreignLanguageText,
    language,
    hasForeignCharacters,
  } = foreignLanguageDefinitionItem ?? {};

  const handleChange = (e) => {
    const newValue = {
      ...foreignLanguageDefinitionItem,
      [e.target.name]: e.target.value,
    };
    handleChangeForeignLanguageDefinitions(newValue);
  };

  const handleChangeForeignLanguageText = (e) => {
    let newValue = {
      ...foreignLanguageDefinitionItem,
      [e.target.name]: e.target.value,
    };

    if (e.target.value && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
      newValue = { ...newValue, hasForeignCharacters: true };
    } else {
      newValue = {
        ...newValue,
        transliteration: "",
        hasForeignCharacters: false,
      };
    }

    handleChangeForeignLanguageDefinitions(newValue);
  };

  const handleChangeLanguage = (value) => {
    const newValue = {
      ...foreignLanguageDefinitionItem,
      language: value,
    };
    handleChangeForeignLanguageDefinitions(newValue);
  };

  return (
    <div
      style={{ border: "1px solid #dedede", padding: "16px", margin: "8px 0" }}
    >
      <h3>Translation {index + 1}</h3>

      <Autocomplete
        size="small"
        id="language"
        name="language"
        value={language}
        onChange={(_event, newValue) => {
          handleChangeLanguage(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={languages}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Language of origin"
            placeholder="e.g. Chinese"
            helperText="e.g. Chinese"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill,
            }}
          />
        )}
        sx={{ mb: 2 }}
      />

      <TextField
        required
        size="small"
        id="foreignLanguageText"
        name="foreignLanguageText"
        label="Word(s) or characters(s)"
        placeholder="e.g. 你好"
        helperText="e.g. 你好"
        fullWidth
        variant="outlined"
        value={foreignLanguageText}
        onChange={handleChangeForeignLanguageText}
        sx={{ mb: 2 }}
      />

      {hasForeignCharacters ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          We found non-English characters in your trademark. Please also provide an English transliteration.
        </Alert>
      ) : null}

      <TextField
        required
        size="small"
        id="translation"
        name="translation"
        label="English translation"
        placeholder="e.g. HELLO"
        helperText="e.g. HELLO"
        fullWidth
        variant="outlined"
        value={translation}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />

      {hasForeignCharacters && (
        <TextField
          required
          size="small"
          id="transliteration"
          name="transliteration"
          label="English transliteration"
          placeholder="e.g. NI HAO"
          helperText="e.g. NI HAO"
          fullWidth
          variant="outlined"
          value={transliteration}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
      )}

      {foreignLanguageDefinitions.length !== 1 && (
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          onClick={() => removeAnotherTranslation(id)}
        >
          Remove translation
        </Button>
      )}
    </div>
  );
}
