import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { STRIPE_PUBLISHABLE_KEY } from "../app.tactical.config";

  
function Payment({ apiResult, userInput, setIsStripeSubmitting }) {
  // console.log(apiResult, userInput);
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: apiResult.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        stripePaymentIntentId={apiResult.stripePaymentIntentId}
        userInput={userInput}
        setIsStripeSubmitting={setIsStripeSubmitting}
      />
    </Elements>
  );
}

export default Payment;
