/**
 * 
 * Set "isProduction" to false when testing on DEV env 
 * 
 */
const isProduction = true;

// Logic
export const IP_AUSTRALIA_API = isProduction
  ? "https://production.api.ipaustralia.gov.au/public/ipright-management-b2b-api/v1"
  : "https://test.api.ipaustralia.gov.au/public/ipright-management-b2b-api/v1";

export const STRIPE_PUBLISHABLE_KEY = isProduction
  ? "pk_live_ooUgqoi1lhIYdrBnUtt582V800rn9gL8FY"
  : "pk_test_KntLSssFzFERmlzF0qcqkq8A007mZD8C2m";
