import axios from "axios";

const HOST =
  window.location.hostname === "localhost"
    ? "https://localhost:7194"
    : "https://trademarkableapi.azurewebsites.net";
const URL_PATH = `${HOST}/api`;

// 1. Create Contact
export const createNewContact = async (payload) => {
  try {
    const url = `${URL_PATH}/IpAustralia/CreateContact`;
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.ipToken}`,
      },
    };
    const response = await axios.post(url, payload, options);
    console.log(response.data.message);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error.response.data.errorMessages[0].message);
    return error.response.data;
  }
};

//2. Create Trademark
export const createNewTrademark = async (payload, validationOnly = false) => {
  const url = `${URL_PATH}/IpAustralia/CreateTrademark?validationOnly=${validationOnly}`; // Pass validationOnly as query parameter`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };

  try {
    const response = await axios.post(url, payload, options);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    window.alert(error.response.data.errorMessages[0].message);
    return false;
  }
};

// Upload Trademark (Optional)
export const uploadAttachment = async (fileUrl) => {
  const url = `${URL_PATH}/IpAustralia/UploadFileFromUrl`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post(url, { fileUrl }, options);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data.errorMessages[0].message;
  }
};
