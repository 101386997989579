
import { IP_AUSTRALIA_API } from "../app.tactical.config";
import axios from "axios";

/**
 * For TESTING purposes ONLY
 * Directly calls IP Australia API
 * Use with browser with CORS disabled
 */

export const getAddressBook = async () => {
  const url = `${IP_AUSTRALIA_API}/me/contacts`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };
  try {
    const response = await axios.get(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteContact = async (id) => {
  const url = `${IP_AUSTRALIA_API}/me/contacts/${id}`;
  const options = {
    headers: {
      "Consent-Flag": true,
      Authorization: `Bearer ${localStorage.ipToken}`,
      "Content-Type": "application/json",
    },
    data: "",
  };
  try {
    const response = await axios.delete(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
